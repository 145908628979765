@import './settings/_Settings';

.App {
	--leading-color: #{$secondaryColor};
	--button-text-color: #{white};
	--hue-value: 172deg;
}

.App.AI {
	--leading-color: #{$primaryColor};
	--button-text-color: #{$darkThemeSecondColor};
	--hue-value: 120deg;
}